a<template>
  <div class="container">
    <el-form :model="form"
             :rules="rules"
             label-position="top"
             ref="form">
      <el-form-item label="活动区域">
        <Area v-model="areaAddress"
              @change="areaChange" />
      </el-form-item>
      <el-form-item label="活动模版"
                    prop="template_type">
        <el-select v-model="form.template_type"
                   placeholder="请选择">
          <el-option label="社保卡业务调研"
                     value="SOCIAL_SECURITY_CARD">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动名称"
                    class="width300"
                    prop="title">
        <el-input type="text"
                  placeholder="请输入活动名称"
                  v-model="form.title"></el-input>
      </el-form-item>

      <el-form-item label="活动图片"
                    prop="img_id">
        <ImgUpload :fileList="imgList"
                   pixel="用于页面配图，建议使用640*200像素"
                   v-model="form.img_id" />
      </el-form-item>
      <el-form-item label="活动规则"
                    prop="rule">
        <Editor v-model="form.rule" />
      </el-form-item>
      <el-form-item label="起止时间"
                    prop="started_at">
        <el-date-picker v-model="dateTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="dateChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="可核销时间"
                    prop="started_at">
        <el-date-picker v-model="convertTime"
                        type="datetimerange"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        range-separator="至"
                        @change="convertChange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="活动管理密码"
                    class="width300"
                    prop="secret">
        <el-input type="text"
                  placeholder="请输入活动管理密码"
                  v-model="form.secret"></el-input>
        <p class="input-tip">用于员工在手机端管理客户信息</p>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   @click="submitForm('form')">立即{{form.id?'修改':'创建'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setActivity, getActivity } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
import Editor from '@/components/Editor'
import Area from '@/components/Area'
import { getImgId } from '@/utils'
export default {
  name: 'SetResearch',
  data () {
    return {
      imgList: [],
      imgList2: [],
      imgList3: [],
      areaAddress: {},
      dateTime: [],
      convertTime: [],
      form: {
        type: 'RESEARCH',
        title: '',
        started_at: '',
        ended_at: '',
        rule: '',
        img_id: '',
        imgData: {},
        activity_id: '',
        list_province: '',
        list_city: '',
        list_district: '',
        list_net: '',
        template_type: '',
        secret: '',
        convert_started_at: '',
        convert_ended_at: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请输入上传图片', trigger: 'change' }
        ],
        rule: [
          { required: true, message: '请输入活动规则', trigger: 'blur' }
        ],
        template_type: [
          { required: true, message: '请选择活动模版', trigger: 'change' }
        ],
        started_at: [
          { required: true, message: '请选择起止时间', trigger: 'change' }
        ],
        convert_started_at: [
          { required: true, message: '请选择核销时间', trigger: 'change' }
        ],
        secret: [
          { required: true, message: '请输入活动管理密码', trigger: 'blur' }
        ]

      }
    }
  },
  components: {
    Area,
    ImgUpload,
    Editor
  },
  methods: {
    dateChange (e) {
      if (e) {
        this.form.started_at = e[0]
        this.form.ended_at = e[1]
      } else {
        this.form.started_at = ''
        this.form.ended_at = ''
      }
    },
    convertChange (e) {
      if (e) {
        this.form.convert_started_at = e[0]
        this.form.convert_ended_at = e[1]
      } else {
        this.form.convert_started_at = ''
        this.form.convert_ended_at = ''
      }
    },
    areaChange (address) {
      const { form } = this
      form.list_province = address.province
      form.list_city = address.city
      form.list_district = address.district
      form.list_net = address.net
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setActivity(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('reSearch')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    },
    async getActivity (id) {
      const res = await getActivity({
        activity_id: id
      })
      if (res.meta.code === 0) {
        const activity = res.data.activity
        activity.activity_id = id
        activity.img_id = getImgId(activity.img_data)
        activity.img_data && this.$set(this.imgList, 0, activity.img_data)
        this.areaAddress = {
          province: activity.list_province,
          city: activity.list_city,
          district: activity.list_district,
          net: activity.list_net
        }
        this.dateTime = [activity.started_at, activity.ended_at]
        this.convertTime = [activity.convert_started_at, activity.convert_ended_at]
        this.form = activity
      }
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getActivity(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
